

/*Icon Placeholder*/
.checkradios-checkbox:after{
    
	content:"";
	height:100%;
	width:100%;
	position:relative;
	display:block;
	vertical-align:bottom;
}


/* Default Styles for checkradios */
.checkradios-checkbox{
    
	font-size:12px;
	
	position:relative;
	display:inline-block;
	vertical-align:bottom;
	text-align:center;
	
	/*border:2px solid #333;*/
	border:0px solid #333;
	color:#333;
	width:1em;
	height:1em;
	cursor:pointer;
	/*padding:0.2em;*/
	
	-moz-box-sizing: content-box;
	-webkit-box-sizing: content-box;
	box-sizing: content-box;
	
	overflow:hidden;

}

/*Hide original*/
.checkradios-checkbox input[type=checkbox]{
    
	margin:0;
	position:absolute;
	top:0;
	left:-9999%;
	width:100%;
	height:100%;
	
	position:absolute;
	display:inline-block;
	vertical-align:bottom;

}

.checkradios-checkbox.unchecked {
	color:#BBBBBB !important;
}

/*Maintenant utilise la couelur de la serie RYAD 16/04/2019*/
/*.checkradios-checkbox.checked {
	color:#000000 !important;
}*/

/*Default styles section end*/

























