.top-1 {
  position: relative !important;
  top: 0.25rem !important;
}

.bottom-1 {
  position: relative !important;
  bottom: 0.25rem !important;
}

.bottom-2 {
  position: relative !important;
  bottom: 0.5rem !important;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

.font-size-smaller {
  font-size: smaller !important;
}

// Place un élément en bas de son contenaire
.pull-down {
  position: absolute;
  bottom: 0;
}

// Pour mettre une image en noir et blanc
.img-grayscale {
  -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
}

// Pour centrer et maximiser une image en conservant son ratio
.img-center {
  display: table;
  width: auto !important;
  height: 100%;
  margin-right: auto;
  margin-left: auto;
  padding: 1em;
}

// Pour mettre une image dans un rond
.img-round {
  border-radius: 100% !important;
}
