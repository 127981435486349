/*
 * Pour le loader
 */
// Affiche le loader sur toute la page
.ats-loading-full-screen {
  background-image: url('../../../../../img/commons/loading.gif');
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: fixed;
  display: block;
  opacity: 0.85;
  background-color: #fff;
  z-index: 9998;  // Pour être en dessous du footer mais au dessus de tous les autres éléments des pages
  text-align: center;
}

// Affiche le loader dans un container spécifique (ne suit pas si on scroll)
.data-vignette-loader { //ryad: un loader pour indiquer une vignette en telechargement
  position:absolute;
  top:50%;
  left:50%;
  transform:translate(-50%, -50%) scale(0.8);
  width:100px;
  height:100px;
  background: url('../../../../../img/commons/loading_img_sticks.gif');//url('//upload.wikimedia.org/wikipedia/commons/thumb/e/e5/Phi_fenomeni.gif/50px-Phi_fenomeni.gif');
  z-index:9999;
  display:none;
}

//les vignettes big sont grande donc on positionne le .data-vignette-loader non pas de facon centrale comme pour les vignettes samll  mais de facon fixe dans l'element .data-vignette-loader-wrapper
//le .data-vignette-loader-wrapper n'est utilisé que pour les vignettes big afin de positionner exactement le loader a cet endroit dans le template jade.
//ca evite le style css compliqué et s'adapte a tous les formats responsive
.data-vignette-loader-wrapper {
  position: relative;
}
